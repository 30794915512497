<template>
  <audio :src="url" ref="media" preload="metadata">
  </audio>
  <div role="application" class="player__wrapper">
    <div class="player__progress" v-if="!error">
      <div class="progress__bar__container">
        <div ref="progress" class="progress__bar__wrapper">
          <div role="progressbar" class="progressBar__buffer" aria-valuemin="0" :aria-valuemax="duration"
               :aria-valuenow="bufferedTo"></div>
          <div role="progressbar" class="progressBar__current" aria-valuemin="0" :aria-valuemax="duration"
               :aria-valuenow="timing" :aria-valuetext="formatTiming"></div>
          <div role="slider" class="progressBar__slider" :class="dragging && 'progressBar__slider--dragged'"
               aria-valuemin="0" :aria-valuemax="duration" draggable="true"
               :aria-valuenow="timing" :aria-valuetext="formatTiming" tabindex="0" @keydown="keyDown"
               @touchstart="touchStart"
               @touchmove="touchMove"
               @touchend="dragEnd"
               @dragstart="dragStart"
               @drag="drag"
               @dragend="dragEnd"/>
        </div>
      </div>
      <div class="player__progress__timings">
        <p class="player__progress__label">{{ this.formatTiming }}</p>
        <p class="player__progress__label">{{ this.formatDuration }}</p>
      </div>
    </div>
    <div class="player__control__wrapper">
      <button class="player__control player__control--back" @click="fastBackward" :disabled="error">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="player__control__icon player__control__icon--alt">
          <path d="M9.195 18.44c1.25.713 2.805-.19 2.805-1.629v-2.34l6.945 3.968c1.25.714 2.805-.188 2.805-1.628V8.688c0-1.44-1.555-2.342-2.805-1.628L12 11.03v-2.34c0-1.44-1.555-2.343-2.805-1.629l-7.108 4.062c-1.26.72-1.26 2.536 0 3.256l7.108 4.061z" />
        </svg>

        <span class="sr-only">back</span></button>
      <button class="player__control player__control--play" @click="clickHandler" :disabled="error">
        <svg v-if="!playing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="currentColor" class="player__control__icon">
          <path fill-rule="evenodd"
                d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                clip-rule="evenodd"/>
        </svg>
        <svg v-if="playing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="player__control__icon">
          <path fill-rule="evenodd" d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z" clip-rule="evenodd" />
        </svg>


        <span class="sr-only">{{ !playing ? "stop" : "play" }}</span></button>
      <button class="player__control player__control--next" @click="fastForward" :disabled="error">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="player__control__icon player__control__icon--alt">
          <path d="M5.055 7.06c-1.25-.714-2.805.189-2.805 1.628v8.123c0 1.44 1.555 2.342 2.805 1.628L12 14.471v2.34c0 1.44 1.555 2.342 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256L14.805 7.06C13.555 6.346 12 7.25 12 8.688v2.34L5.055 7.06z" />
        </svg>

        <span
          class="sr-only">next</span></button>
    </div>
  </div>
  <p v-if="loading">Loading content...</p>
  <p v-if="error">Error occurred loading content</p>
</template>

<script>
export default {
  name: 'PlayerComponent',
  props: {
    url: String
  },
  mounted() {
    this.loading = true;

    this.$refs.media.addEventListener("loadedmetadata", ({target}) => {
      this.duration = target.duration;
      this.loading = false;
    });

    this.$refs.media.addEventListener("timeupdate", ({target}) => {
      this.timing = target.currentTime;
    });

    this.$refs.media.addEventListener("seeked", ({target: {buffered}}) => {
      this.lastBuffer = buffered.length - 1;
      this.ranges = buffered;
    });

    this.$refs.media.addEventListener("progress", ({target: {buffered}}) => {
      this.lastBuffer = buffered.length - 1;
      this.ranges = buffered;
    });

    this.$refs.media.addEventListener("canplaythrough", () => {
      this.loading = false;
    });

    this.$refs.media.addEventListener("error", () => {
      this.loading = false;
      this.error = true;
    });

    this.$refs.media.addEventListener("waiting", () => {
      this.loading = true;
    });

    this.$refs.media.addEventListener("playing", () => {
      if (this.loading)
        this.loading = false;
    });

    this.dragCursor = new Image();
    this.dragCursor.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Uw8AAn0BfY81YKcAAAAASUVORK5CYII=";
  },
  data() {
    return {
      duration: 0,
      timing: 0,
      seekable: 0,
      ranges: [],
      lastBuffer: 0,
      playing: false,
      loading: false,
      error: false,

      dragCursor: undefined,
      dragging: false,
      initialTiming: 0,
      timingAmount: 0,
      dragStartPos: null
    }
  },
  computed: {
    formatTiming() {
      const minutes = Math.floor(this.timing / 60).toString().padStart(2, "0");
      const seconds = Math.floor((this.timing % 60)).toString().padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    formatDuration() {
      const minutes = Math.floor(this.duration / 60).toString().padStart(2, "0");
      const seconds = Math.floor((this.duration % 60)).toString().padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    bufferedTo() {
      if (this.ranges.length) {
        return this.ranges.end(this.ranges.length - 1);
      }

      return this.timing;
    },
    timingPercentage() {
      return (this.timing / this.duration) * 100 + "%";
    },
    bufferPercentage() {
      return (this.bufferedTo / this.duration) * 100 + "%";
    },
    canPlay() {
      return this.playing && !this.dragging;
    }
  },
  methods: {
    clickHandler() {
      this.playing = !this.playing;
    },
    fastForward() {
      this.timing = Math.min(this.timing + 5, this.duration);
      this.$refs.media.currentTime = this.timing;
    },
    fastBackward() {
      this.timing =  Math.max(this.timing - 5, 0)
      this.$refs.media.currentTime = this.timing;
    },
    keyDown({key}) {
      switch (key) {
        case "ArrowLeft":
          this.fastBackward();
          break;
        case "ArrowRight":
          this.fastForward();
          break;
      }
    },
    touchStart({touches}) {
      const [touch] = touches;
      this.dragging = true;
      this.dragStartPos = touch.pageX;
      this.initialTiming = this.timing;
    },
    touchMove(event) {
      event.preventDefault();
      const [touch] = event.touches;
      this.handleMove(touch.pageX);
    },
    dragStart({pageX, dataTransfer}) {
      dataTransfer.effectAllowed = "move";
      this.dragging = true;
      this.dragStartPos = pageX;
      this.initialTiming = this.timing;
      dataTransfer.setDragImage(this.dragCursor, 0, 0);
    },
    drag(event) {
      const {pageX} = event;
      this.handleMove(pageX);
    },
    dragEnd() {
      this.dragging = false;
    },
    sliderWidth() {
      if (!this.$refs.progress)
        return 0;
      return this.$refs.progress.getBoundingClientRect().width;
    },
    sliderLeft() {
      if (!this.$refs.progress)
        return 0;
      return this.$refs.progress.getBoundingClientRect().left;
    },
    handleMove(position) {
      if (position !== 0) {
        const move = position - this.dragStartPos;

        const normalizeMove = this.normalizeMove(move);
        const timingAmount = normalizeMove * this.duration;

        if (this.initialTiming + timingAmount < 0 && move < 0) { // if move is below start, normalize to 0
          this.timing = 0
        } else if (this.initialTiming + timingAmount > this.duration || (this.initialTiming + timingAmount < 0 && move >= 0)) { // if move is over duration, normalize to duration
          this.timing = this.duration;
        } else
          this.timing = this.initialTiming + timingAmount;

        // update current time
        this.$refs.media.currentTime = this.timing;
      }
    },
    normalizeMove(move) {
      const width = this.sliderWidth();
      const maxMove = this.maxMove(move, width);
      if(move >= 0)
        return Math.min(move, maxMove) / width;

      return Math.max(move, -maxMove) / width;
    },
    maxMove(move, width) {
      const left = this.sliderLeft();
      const padding = 10; // this is due to the fact that I don't know how to better approximate max move
      if(move >= 0)
        return (width - (this.dragStartPos - left)) + padding;

      return (this.dragStartPos - left) - padding;
    }
  },
  watch: {
    canPlay(playing) {
      if (playing)
        this.$refs.media.play();
      else
        this.$refs.media.pause();
    },
    timing(timing) {
      if (timing >= this.duration)
        this.playing = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.player__wrapper {
  background: #000;
  padding: 1rem 2rem;
}

.player__progress {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: .1rem;
}

.player__progress__timings {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
}

.player__progress__label {
  color: #FFF;
  margin: .5rem 0 0;
}

.progress__bar__container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.progress__bar__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.progressBar__buffer {
  position: absolute;
  left: 0;
  background: gray;
  width: v-bind(bufferPercentage);
  border-radius: .25rem;
  height: .5rem;
}

.progressBar__current {
  position: absolute;
  left: 0;
  background: #c7bca6;
  width: v-bind(timingPercentage);
  border-radius: .25rem;
  height: .5rem;
}

.progressBar__slider {
  opacity: 0;
  position: absolute;
  left: v-bind(timingPercentage);
  transform: translateX(-50%);
  background: white;
  width: 1rem;
  height: 1rem;
  border-radius: .5rem;
  box-sizing: border-box;
  cursor: pointer;
}

.progressBar__slider:hover, .progressBar__slider:focus, .progressBar__slider--dragged {
  opacity: 1;
  cursor: pointer !important;
}

.player__control__wrapper {
  display: flex;
  flex-flow: row;
  gap: .5rem;
  margin: auto;
  justify-content: center;
}

.player__control {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  border-radius: 50%;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
}

.player__control--play {
  background: #c7bca6;
}

.player__control__icon {
  height: 1rem;
  width: 1rem;
}

.player__control__icon--alt {
  color: white;
  fill: currentColor;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

</style>
